.filterButtons {
  display: flex;
  overflow: scroll;
  white-space: nowrap;
}

.filterButtons button {
  white-space: nowrap;
}

.filterButtons button:last-child {
  margin-right: 20px;
}